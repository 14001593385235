import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';
import { useHistory, withRouter, NavLink } from 'react-router-dom';
/* Material UI */
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
/* React-icons */
import { FiArrowRightCircle } from "react-icons/fi";
/* Global state */
import { UserContext } from '../../../Context/UserContext';
import { OrderActionsContext } from '../../../Context/OrderContext';
import { UPDATE_ORDER } from '../../../Context/reducers/orderReducer';
/* Components */
import ConnectionDialog from '../../dialogs/ConnectionDialog/ConnectionDialog';
import CustomButton from '../../lib/CustomButton/CustomButton';
/* Utils */
import { getTokenFromLocalStorage } from '../../../Utils/localStorage';

/* Styles */
const styles = {
    buttonsWrapper: {
        marginTop: '4px',
        marginBottom: '4px'
    },
};

const UnitCardButtons = ({ unit, expired }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const arrowsBreakpoint = useMediaQuery({
        query: `(min-width: 320px)`
    });
    
    const [ dialogOpen, setDialogOpen ] = useState(false);
    
    const dispatchOrder = useContext(OrderActionsContext);
    const { user } = useContext(UserContext);

    /**
     * Handle connect in normal and powersave mode
     */
    const handleConnect = () => {
        
        UnitCardButtons.openWindow = null;

        /* Normal mode */
        if (unit.pwr_mode === "normal") {
            
            /* Open new tab with app for unit configuration */                       
            const url = `https://remote.bixion.com/?goto=${unit.serial_number}&token=${getTokenFromLocalStorage()}&old=true`;

            if (UnitCardButtons.openWindow !== null) {
                UnitCardButtons.openWindow.close();
            }

            let owindow = window.open("", 'Unit', null, false);
            if (owindow != null)
            {
                let html = "";
                html += "<html><head></head><body><form id='formid' method='post' action='" + url +"'>";
                html += "<input type='hidden' name='s' value='" + unit.secure_id + "'/>";
                html += "<input type='hidden' name='vg' value='" + unit.vpn_ip + "'/>";
                html += "</form><script type='text/javascript'>document.getElementById(\"formid\").submit()</script></body></html>";
                owindow.document.write(html);
            }

            UnitCardButtons.openWindow = owindow;
        }
        
        /* Powersave mode */
        else {
            setDialogOpen(true);
        }
    }

    /**
     * Handle order in case of expired unit
     */
    const handleOrder = () => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: "units",
            orderItem: {
                unit_id: unit.id,
                unit_name: unit.name,
                unit_serial: unit.serial_number,
                services: [
                    {
                        service_name: "Remote management",
                        months: 1,
                    }
                ] 
            }
        })
        history.push('/services/units');
    }

    //console.log("[UnitCardButtons]");

    return (

        <>
            {user.type !== "admin" ?

                <Grid container sx={styles.buttonsWrapper}>
                    {user.type !== "customer" ? (
                        <Grid item xs={6} style={{padding: '8px 1px'}}>
                            <CustomButton
                                component={NavLink}
                                style={{width: '100%'}}
                                to={`unit/${unit.id}/logs`}
                            >
                                {t('dashboard.unit.button.showLogs')}
                            </CustomButton>
                        </Grid>
                    ) : null}
                    <Grid item xs={user.type !== "customer" ? 6 : 12} style={{padding: '8px 0'}}>
                        <Tooltip title={!expired ? t('dashboard.unit.tooltip.connectToTheUnit') : t('dashboard.unit.tooltip.remoteManagementServiceExpiredExtendItByClickingOnTheButton')}>
                            <CustomButton
                                color={!expired ? 'primary' : 'secondary'}
                                endIcon={arrowsBreakpoint && <FiArrowRightCircle style={{height: 'auto'}} />}
                                onClick={!expired ? handleConnect : handleOrder}
                                style={{width: '100%'}}
                            >
                                {!expired ? t('dashboard.unit.button.connect') : t('dashboard.unit.button.order')}
                            </CustomButton>
                        </Tooltip>
                    </Grid>
                </Grid>

                :

                <Grid container sx={styles.buttonsWrapper}>
                    {expired ?
                        <Grid item xs={4} style={{padding: '8px 1px'}}>
                            <Tooltip title={t('dashboard.unit.tooltip.remoteManagementServiceExpiredExtendItByClickingOnTheButton')}>
                                <CustomButton
                                    color="secondary"
                                    endIcon={arrowsBreakpoint && <FiArrowRightCircle style={{height: 'auto'}} />}
                                    onClick={handleOrder}
                                    style={{width: '100%'}}
                                >
                                    {t('dashboard.unit.button.order')}
                                </CustomButton>
                            </Tooltip>
                        </Grid>
                        : null
                    }
                    <Grid item xs={expired ? 4 : 6} style={{padding: '8px 1px'}}>
                        <CustomButton
                            component={NavLink}
                            style={{width: '100%'}}
                            to={`unit/${unit.id}/logs`}
                        >
                            {t('dashboard.unit.button.showLogs')}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={expired ? 4 : 6} style={{padding: '8px 1px'}}>
                        <Tooltip title={t('dashboard.unit.tooltip.connectToTheUnit')}>
                            <CustomButton
                                endIcon={arrowsBreakpoint && <FiArrowRightCircle style={{height: 'auto'}} />}
                                onClick={handleConnect}
                                style={{width: '100%'}}
                            >
                                {t('dashboard.unit.button.connect')}
                            </CustomButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            }

            {/* Connection dialog */}
            <ConnectionDialog 
                open={dialogOpen} 
                handleClose={() => setDialogOpen(false)} 
                unit={{
                    id: unit.id,
                    name: unit.name,
                    secure_id: unit.secure_id,
                    vpn_ip: unit.vpn_ip,
                    serial_number: unit.serial_number,
                }} 
            />
        </>
    );

}

export default React.memo(withRouter(UnitCardButtons));
