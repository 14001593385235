import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from 'react-router-dom';
/* Material UI */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
/* Components */
import BrowsersTable from '../components/Services/Browsers/BrowsersTable';
import CustomButton from '../components/lib/CustomButton/CustomButton';
/* Global state */
import { OrderContext, OrderActionsContext } from '../Context/OrderContext';
import { DELETE_ORDER } from '../Context/reducers/orderReducer';

/* Styles */
const styles = {
    typography: {
        fontSize: '16px', 
        marginRight: '16px',
        '@media (max-width: 480px)': {
            marginRight: 0
        },
    },
    bottomLine: {
        width: '100%', 
        height: '70px',
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        backgroundColor: '#E0E0E0', 
        zIndex: 1199, 
        padding: '16px', 
        borderTop: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'secondary.main',
        '@media (max-width: 480px)': {
            height: 'fit-content',
            flexWrap: 'wrap'
        },
        '& button': {
            '@media (max-width: 767px)': {
                margin: '2px 0'
            },
        }
    },
    paper: {
        padding: '16px',
        borderRadius: '8px',
    },
    container: {
        display: 'flex',
        alignItems: 'start',
        padding: '0 0px',
        gap: '16px',
    },
    infoIcon: {
        color: 'secondary.main'
    },
    info: {
        fontWeight: 'bold',
        color: 'secondary.main'
    }
};

const ServicesSelectedBrowsers = () => {

    const history = useHistory();
    const { t } = useTranslation();

    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);

    return (
        <div style={{position: 'relative', marginBottom: '70px'}}>

            <Paper sx={styles.paper} elevation={3}>
                <Box sx={styles.container}>
                    <InfoIcon sx={styles.infoIcon} />
                    <Typography variant="subtitle2" sx={styles.info}>We are preparing the transition to a new storage. For this reason, it is only possible to order the service until April 2025.</Typography>
                </Box>
            </Paper>

            <br />
            
            {/* Browsers table */}
            {currentOrderState.browsers.order.length !== 0 ? <BrowsersTable browsers={currentOrderState.browsers.order} /> : <Redirect to="/services/browsers" />}

            <br />

            {/* Summary */}
            <Box sx={styles.bottomLine}>
                <Typography sx={styles.typography}>
                    {t('services.summary.label.totalPrice')}:&nbsp;<strong>{currentOrderState.browsers.price} €</strong>
                </Typography>
                <div style={{display: 'flex', marginLeft: '12px', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                    <CustomButton color="secondary" onClick={() => window.history.back()}>
                        {t('services.summary.button.back')}
                    </CustomButton>
                    <CustomButton color="secondary" onClick={() => dispatchOrder({type: DELETE_ORDER})} style={{marginLeft: '8px'}}>
                        {t('services.summary.button.dismissOrder')}
                    </CustomButton>
                    <CustomButton 
                        color="primary" 
                        onClick={() => history.push('/services/browsers/summary')} 
                        style={{marginLeft: '8px'}}
                        disabled={parseInt(currentOrderState.browsers.price) === 0}
                    >
                        {t('services.summary.button.proceedToSummary')}
                    </CustomButton>
                </div>
            </Box>
        </div>
    );
}

export default ServicesSelectedBrowsers;
